<template>
  <div class="container">
    <el-tabs v-model="activeName">
      <el-tab-pane label="借阅" name="first">
        <el-row>
          <el-col :span="6">
            <el-card class="box-card" shodow="always">
              <div class="clearfix">
                <span>当前读者</span>
              </div>
              <el-form :model="formReaderModel" class="demo-form-inline" label-width="100px">
                <el-form-item>
                  <img :src="formReaderModel.StrAvatarURL?Url+formReaderModel.StrAvatarURL:DefaultUrl" style="width:100px;height:100px">
                </el-form-item>
                <el-form-item label="读者证号">
                  <el-input v-model="formReaderModel.StrReaderCardNumber" readonly="true" />
                </el-form-item>
                <el-form-item label="姓名">
                  <el-input v-model="formReaderModel.StrName" readonly="true" />
                </el-form-item>
                <el-form-item label="单元">
                  <el-input v-model="formReaderModel.StrUnitName" readonly="true" />
                </el-form-item>
                <el-form-item label="类型">
                  <el-input v-model="formReaderModel.StrReaderTypeName" readonly="true" />
                </el-form-item>
                <el-form-item label="已借">
                  <el-input v-model="formReaderModel.IntBookBorrowNum" readonly="true" />
                </el-form-item>
                <el-form-item label="可借">
                  <el-input v-model="formReaderModel.IntAvailableBookBorrowNum" readonly="true" />
                </el-form-item>
              </el-form>
            </el-card>
          </el-col>
          <el-col :span="18">
            <div style="padding:50px 0;text-align:center">
              <el-form :model="formJYModel" class="demo-form-inline" size="medium" :inline="true">

                <el-form-item>
                  <div style="display:flex;">
                    <div style="color:#46c169;font-weight:bold;flex-grow:0;flex-shrink:0;text-align:right;padding-right:10px;width:150px;font-size: 16px;">①读者卡号</div>
                    <el-input v-model="formJYModel.StrReaderCardNumber" @keydown.enter.prevent="submit('dz')" />
                  </div>
                  <div>按Enter(回车键)确定</div>
                  <el-button type="primary" @click="startDig">人脸查询</el-button>
                </el-form-item>
                <el-form-item>
                  <div style="display:flex;">
                    <div style="color:#46c169;font-weight:bold;flex-grow:0;flex-shrink:0;text-align:right;padding-right:10px;width:150px;font-size: 16px;">②图书/期刊条码</div>
                    <el-input ref="enterBlur" v-model="formJYModel.StrBookBarcode" :disabled="isShow" placeholder="请输入图书/期刊条码" @keydown.enter.prevent="submit('ts')" />
                  </div>
                  <div>按Enter(回车键)确定</div>
                </el-form-item>
                <el-form-item>
                  <div style="display:flex;">
                    <div style="color:#46c169;font-weight:bold;flex-grow:0;flex-shrink:0;text-align:right;padding-right:10px;width:150px;font-size: 16px;">③借阅类型</div>
                    <el-radio-group v-model="borrowType" size="medium">
                      <el-radio-button label="book">图书</el-radio-button>
                      <el-radio-button label="journal">期刊</el-radio-button>
                    </el-radio-group>
                  </div>
                </el-form-item>
              </el-form>
            </div>



            <el-dialog
                title="人脸数据采集"
                v-model="dialogVisible1"
                width="35%"
                height="50%"
                :before-close="handleClose"
                :custom-class="'dialog-class'"
            >
              <div class="media-container">
                <div class="video-container">
                  <video ref="video" autoplay style="display: none;"></video>
                  <canvas ref="canvas"></canvas>
                  <br />缩小
                  <input
                      type="range"
                      v-model="zoomLevel"
                      min="1"
                      max="4"
                      step="0.1"
                      @input="updateCanvas"
                  />
                  <!--                  <label for="zoom">Zoom: {{ zoomLevel }}</label>-->
                  <label for="zoom">放大</label>
                </div>

                <!-- 拍照后显示照片 -->
                <!--                <div v-if="photoData" class="photo-container">-->
                <!--                  <h3>采集到的照片数据：</h3>-->
                <!--                  <img :src="photoData" alt="拍摄的照片" />-->
                <!--                </div>-->
              </div>

              <!-- 按钮区域 -->
              <div class="button-container">
                <el-button type="primary" @click="takePhoto">人脸识别</el-button>
                <!--                <el-button type="success" @click="uploadPhoto" :disabled="!photoData">人脸识别</el-button>-->
              </div>
            </el-dialog>



            <el-card class="box-card">
              <div class="clearfix borrow-statistics">
                <p class="borrow-desc">当前借阅信息</p>
                <span class="borrow-statistics_num">当前借阅数量： {{ formReaderModel.IntBookBorrowNum?formReaderModel.IntBookBorrowNum:0 }} 本</span>
              </div>
              <el-table
                  border
                  fit
                  highlight-current-row
                  style="width: 100%;"
                  :data="jyxxList"
              >
                <el-table-column
                    label="分类号"
                    prop="id"
                    align="center"
                >
                  <template v-slot="{row}">
                    <span>{{ row.StrBookTypeName }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="编号(图书条码)" align="center">
                  <template v-slot="{row}">
                    <span>{{ row.StrBookBarcode }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="图书名称(正题名)" align="center">
                  <template v-slot="{row}">
                    <span>{{ row.StrPositiveTitle }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="应还时间" align="center">
                  <template v-slot="{row}">
                    <span>{{ new Date(row.DTReturnDate).toLocaleString() }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="超期" align="center">
                  <template v-slot="{row}">
                    <span>{{ new Date()-new Date(row.DTReturnDate)>= 0 ? '超期' : '未超期' }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="借阅时间" align="center">
                  <template v-slot="{row}">
                    <span>{{ new Date(row.DTCreatedDate).toLocaleString()}}</span>
                    <!-- <span>{{ row.strCollectionLocation|filterKey2Value(swList,'strId','strName','未选择书屋') }}</span> -->
                  </template>
                </el-table-column>
              </el-table>
            </el-card>
          </el-col>
        </el-row>
      </el-tab-pane>
      <el-tab-pane label="归还" name="second">
        <div class="app-container">
          <el-row :gutter="20">
            <el-col :span="6">
              <el-card class="box-card" shodow="always">
                <div class="clearfix">
                  <span>当前读者</span>
                </div>
                <el-form :model="formDQDZModel" class="demo-form-inline" label-width="100px">
                  <el-form-item>
                    <img :src="formReaderModel.StrAvatarURL?Url+formReaderModel.StrAvatarURL:DefaultUrl" style="width:100px;height:100px">
                  </el-form-item>
                  <el-form-item label="读者证号">
                    <el-input v-model="formDQDZModel.StrReaderCardNumber" readonly="true" />
                  </el-form-item>
                  <el-form-item label="姓名">
                    <el-input v-model="formDQDZModel.StrName" readonly="true"  />
                  </el-form-item>
                  <el-form-item label="单元">
                    <el-input v-model="formDQDZModel.StrUnitName" readonly="true"  />
                  </el-form-item>
                  <el-form-item label="类型">
                    <el-input v-model="formDQDZModel.StrReaderTypeName" readonly="true"  />
                  </el-form-item>
                </el-form>
              </el-card>
            </el-col>
            <el-col :span="18">
              <div style="padding:20px 0;text-align:center">
                <!--                <div :model="formGHModel" class="demo-form-inline" size="medium" :inline="true">-->
                <el-form :model="formJYModel" class="demo-form-inline" size="medium" :inline="true">
                  <el-form-item>
                    <div style="display:flex;">
                      <div style="color:#46c169;font-weight:bold;flex-grow:0;flex-shrink:0;text-align:right;padding-right:10px;width:150px;font-size: 16px;">请输入条码</div>
                      <el-input v-model="formGHModel.StrBookBarcode" @keydown.enter.prevent="submit('gh')" style="width: 200px"/>
                      <div style="flex-grow:0;flex-shrink:0;text-align:left;padding-left:15px;width:150px;font-size: 14px;">按Enter(回车键)确定</div>
                    </div>
                  </el-form-item>
                  <el-form-item>
                    <div style="display:flex;">
                      <div style="color:#46c169;font-weight:bold;flex-grow:0;flex-shrink:0;text-align:right;padding-right:10px;width:150px;font-size: 16px;">②归还类型</div>
                      <el-radio-group v-model="returnType" size="medium">
                        <el-radio-button label="book">图书</el-radio-button>
                        <el-radio-button label="journal">期刊</el-radio-button>
                      </el-radio-group>
                    </div>
                  </el-form-item>
                </el-form>
                <!--                </div>-->
              </div>
              <el-card shadow="always">
                <div class="clearfix borrow-statistics">
                  <p class="borrow-desc">归还图书信息</p>
                </div>
                <el-table
                    border
                    fit
                    highlight-current-row
                    style="width: 100%;"
                    :data="bookmsglist">
                  <el-table-column
                      label="分类号"
                      prop="id"
                      align="center"
                      width="150">
                    <template v-slot="{row}">
                      <span>{{ row.StrBookTypeName }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column label="编号(图书条码)" align="center">
                    <template v-slot="{row}">
                      <span>{{ row.StrBookBarcode }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column label="图书名称(正题名)" align="center">
                    <template v-slot="{row}">
                      <span>{{ row.StrPositiveTitle }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column label="应还时间" align="center">
                    <template v-slot="{row}">
                      <span>{{ new Date(row.DTReturnDate).toLocaleDateString() }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column label="超期" align="center">
                    <template v-slot="{row}">
                      <span>{{ new Date()-new Date(row.DTReturnDate)>= 0 ? '超期' : '未超期' }}</span>
                    </template>
                  </el-table-column>
                </el-table>
              </el-card>
              <el-card shadow="always">
                <div class="clearfix borrow-statistics">
                  <p class="borrow-desc">当前读者借阅信息</p>
                  <span class="borrow-statistics_num">当前读者借阅数量： {{ dzjyxxList ? dzjyxxList.length:0 }}</span>
                </div>
                <el-table
                    border
                    fit
                    highlight-current-row
                    style="width: 100%;"
                    :data="dzjyxxList"
                >
                  <el-table-column
                      label="分类号"
                      prop="id"
                      align="center"
                      width="150"
                  >
                    <template v-slot="{row}">
                      <span>{{ row.StrBookTypeName }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column label="编号(图书条码)" align="center">
                    <template v-slot="{row}">
                      <span>{{ row.StrBookBarcode }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column label="图书名称(正题名)" align="center">
                    <template v-slot="{row}">
                      <span>{{ row.StrPositiveTitle }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column label="应还时间" align="center">
                    <template v-slot="{row}">
                      <span>{{ new Date(row.DTReturnDate).toLocaleDateString() }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column label="超期" align="center">
                    <template v-slot="{row}">
                      <span>{{ new Date()-new Date(row.DTReturnDate)>= 0 ? '超期' : '未超期' }}</span>
                    </template>
                  </el-table-column>
                </el-table>
              </el-card>
            </el-col>
          </el-row>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>




import {ElMessage} from "element-plus";
import Dynamsoft from 'dynamsoft-camera-enhancer';



export default {
  name:'Circulation',
  data() {
    return {
      zoom:false,//是否支持调节,默认不支持
      canvasWidth: 640, // Canvas 的初始宽度
      canvasHeight: 480, // Canvas 的初始高度
      p5Instance: null, // 存储 p5 实例
      supportsZoom: false,
      maxZoom: 10,
      zoomLevel: 1, // 初始化为最小焦距
      photoURL: null, // 存储拍照后的图片URL
      photoFile: null, // 存储拍照后的图片文件
      photoData: null, // 存储拍照后的图片数据
      stream: null, // 用来存储摄像头视频流
      dialogVisible1: false,
      that: this,
      returnType: 'book',
      borrowType: 'book',
      activeName: 'first',
      list: null,
      total: 0,
      bookmsglist:'',
      fine:false,
      checked:false,
      finemoney:0,
      ltQuery: {
        pageIndex: 1,
        pageSize: 10,
        strUnitId: '',
        keywords: '',
        keyName: 'strBookBarcode',
        dtStartRecordDate: '',
        dtEndRecordDate: '',
        strType: ''
      },
      tscxQuery: {
        keywords: '',
        keyName: 'strBookBarcode'
      },
      dialogFormVisible: false,
      dialogStatus: '',
      textMap: {
        update: '编辑',
        create: '添加'
      },
      rules: {
      },
      downloadLoading: false,
      xsSize: 24,
      smSize: 24,
      mdSize: 12,
      lgSize: 12,
      xlSize: 12,
      bmTreeData: [],
      formInline: {
        user: '',
        region: ''
      },
      formReaderModel: {},
      formJYModel: {},
      isShow: true,
      jyxxList: [],
      formDQDZModel: {},
      formGHModel: {},
      dzjyxxList: [],
      formDZCXModel: {
        keyName: '卡号'
      },
      cxyjList: [
        {
          name: '卡号'
        },
        {
          name: '姓名'
        },
        {
          name: '学号'
        }
      ],
      formXJDZXXModel: {},
      xjtsList: [],
      props: {
        label: 'strNodeName'
      },
      ltcxList: [],
      ltcxLoading: false,
      lxdaList: [],
      tsdaList: [],
      tsdaLoading: true,
      formYCDZXXModel: {},
      ltQueryList: [
        {
          name: '图书条码',
          value: 'strBookBarcode'
        },
        {
          name: '图书书名',
          value: 'strPositiveTitle'
        },
        {
          name: '读者姓名',
          value: 'strName'
        },
        {
          name: '读者卡号',
          value: 'strCardNumber'
        }
      ],
      tsQuery: {
        pageIndex: 1,
        pageSize: 10,
        keyName: 'strBookBarcode'
      },
      tscxLoading: false,
      tscxTotal: 0,
      tsQueryList: [
        {
          name: '图书条码',
          value: 'strBookBarcode'
        },
        {
          name: '图书书名',
          value: 'strPositiveTitle'
        },
        {
          name: 'ISBN',
          value: 'strIssue'
        },
        {
          name: '作者',
          value: 'strFirstResponsible'
        }
      ],
      tscxList: [],
      sjList: [],
      formTSYCModel: {
        keyName: '卡号'
      },
      cxycList: [],
      Url: this.localpath+'avatars/',
      DefaultUrl:this.localpath + 'avatars/default.png',
      dialogVisible: false,
      message: '',
      njList: [],
      bjList: [],
      formModel: {},
      strUnitId: {
        strUnitId: ''
      },
      returnCount: 0,
      renewCount: 0
    }
  },
  created() {

  },
  mounted() {

  },


  methods: {

    handleClose(done) {
      this.dialogVisible1 = false
      this.photoData = null; // 清空照片数据
      this.photoURL = null; // 清空照片URL
      this.photoFile = null; // 清空照片文件
      this.$nextTick(() => {
        this.stopCamera()
      });

      done()
    },
    // 按enter关闭弹框
    //   close() {
    //     this.dialogVisible = false
    //   },
    // 当前借阅读者信息以及借阅信息
    getborrowReader() {
      const param = {
        StrReaderCardNumber: this.formJYModel.StrReaderCardNumber
      }
      //获得读者信息
      this.$axios({
        url: this.localpath + 'readers',
        method: 'post',
        data: JSON.stringify(param),
        //发送格式为json
        headers:
            {
              'Content-Type': 'application/json',
              'dataType': 'json',
              'Authorization': localStorage.getItem("token")
            }
      }).then(res => {
        if (res.data.ok) {
          this.isShow = false
          this.formReaderModel = res.data.msg
        } else {
          this.$message({
            message: res.data.msg,
            type: 'error'
          })
        }
      })
      //获得读者借阅记录
      this.$axios({
        url: this.localpath + 'borrowrecords/readercard/' + this.formJYModel.StrReaderCardNumber,
        method: 'get',
        //发送格式为json
        headers:
            {
              'Content-Type': 'application/json',
              'dataType': 'json',
              'Authorization': localStorage.getItem("token")
            }
      }).then(res => {
        if (res.data.ok) {
          this.jyxxList = res.data.msg
          for(var i in this.jyxxList){
            if(this.jyxxList[i].StrType === "Journal"){
              this.jyxxList[i].StrBookTypeName = this.jyxxList[i].StrISBN
            }
          }
        } else {
          this.$message({
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },

    //通过人脸获取
    getborrowReaderFace(val) {
      const param = {
        StrReaderCardNumber: val
      }
      //获得读者信息
      this.$axios({
        url: this.localpath + 'readers',
        method: 'post',
        data: JSON.stringify(param),
        //发送格式为json
        headers:
            {
              'Content-Type': 'application/json',
              'dataType': 'json',
              'Authorization': localStorage.getItem("token")
            }
      }).then(res => {
        if (res.data.ok) {
          this.isShow = false
          this.formReaderModel = res.data.msg
        } else {
          this.$message({
            message: res.data.msg,
            type: 'error'
          })
        }
      })
      //获得读者借阅记录
      this.$axios({
        url: this.localpath + 'borrowrecords/readercard/' + val,
        method: 'get',
        //发送格式为json
        headers:
            {
              'Content-Type': 'application/json',
              'dataType': 'json',
              'Authorization': localStorage.getItem("token")
            }
      }).then(res => {
        if (res.data.ok) {
          this.jyxxList = res.data.msg
          for(var i in this.jyxxList){
            if(this.jyxxList[i].StrType === "Journal"){
              this.jyxxList[i].StrBookTypeName = this.jyxxList[i].StrISBN
            }
          }
        } else {
          this.$message({
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },

    getReturnReader(val) {
      const param = {
        StrReaderCardNumber: val
      }
      //获得读者信息
      this.$axios({
        url: this.localpath + 'readers',
        method: 'post',
        data: JSON.stringify(param),
        //发送格式为json
        headers:
            {
              'Content-Type': 'application/json',
              'dataType': 'json',
              'Authorization': localStorage.getItem("token")
            }
      }).then(res => {
        if (res.data.ok) {
          // this.isShow = false
          // this.formReaderModel = res.data.msg

          this.formDQDZModel=res.data.msg
          this.getreturnreader(val)
        } else {
          this.$message({
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },
    //获得归还的读者信息
    getreturnreader(val) {
      //获得读者借阅记录
      this.$axios({
        url: this.localpath + 'borrowrecords/readercard/' + val,
        method: 'get',
        //发送格式为json
        headers:
            {
              'Content-Type': 'application/json',
              'dataType': 'json',
              'Authorization': localStorage.getItem("token")
            }
      }).then(res => {
        if (res.data.ok) {
          this.dzjyxxList = res.data.msg
          for(var i in this.dzjyxxList){
            if(this.dzjyxxList[i].StrType === "Journal"){
              this.dzjyxxList[i].StrBookTypeName = this.dzjyxxList[i].StrISBN
            }
          }
        } else {
          this.$message({
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },
    //获得归还的图书信息
    getbook(val){
      this.$axios({
        url: this.localpath + 'borrowrecords/barcode/' + val,
        method: 'get',
        //发送格式为json
        headers:
            {
              'Content-Type': 'application/json',
              'dataType': 'json',
              'Authorization': localStorage.getItem("token")
            }
      }).then(res => {
        //这里可以得到读者卡号
        if (res.data.ok) {
          this.bookmsglist=new Array()
          if(res.data.msg.StrType === "Journal"){
            res.data.msg.StrBookTypeName = res.data.msg.StrISBN
          }
          this.bookmsglist.push(res.data.msg)

          this.getReturnReader(res.data.msg.StrReaderCardNumber)
          console.log(this.bookmsglist)
        } else {
          this.$message({
            message: res.data.msg,
            type: 'error'
          })
        }
        this.listLoading=false
      })
    },
    // submitJournal(val){
    //
    // },
    // 读者，归还，借阅确定
    submit(val) {

      if (val === 'dz') {
        this.getborrowReader()
      } else if (val === 'ts') {
        let param,borrowurl;
        if(this.borrowType === 'book'){
          param = {
            StrBookBarcode: this.formJYModel.StrBookBarcode,
            StrReaderCardNumber: this.formJYModel.StrReaderCardNumber
          }
          borrowurl = 'books/borrow'
        }else{
          param = {
            StrJournalBarcode: this.formJYModel.StrBookBarcode,
            StrReaderCardNumber: this.formJYModel.StrReaderCardNumber
          }
          borrowurl = 'journals/borrow'
        }
        this.$axios({
          url: this.localpath + borrowurl,
          method: 'post',
          data: JSON.stringify(param),
          //发送格式为json
          headers:
              {
                'Content-Type': 'application/json',
                'dataType': 'json',
                'Authorization': localStorage.getItem("token")
              }
        }).then(res => {
          if (res.data.ok) {
            this.dialogVisible = true
            this.$message({
              message: '借阅成功',
              type: 'success'
            })
            this.formJYModel.StrBookBarcode = ""
            this.getborrowReader()
          } else {
            this.$message({
              message: res.data.msg,
              type: 'error'
            })
          }
        })
      } else if (val === 'gh') {
        this.getbook(this.formGHModel.StrBookBarcode)
        // this.getReader(this.formGHModel.StrBookBarcode)
        //归还图书
        let param,url;
        if(this.returnType == 'book'){
          param = {
            StrBookBarcode: this.formGHModel.StrBookBarcode
          }
          url='books/return'
        }else{
          param = {
            StrJournalBarcode: this.formGHModel.StrBookBarcode
          }
          url='journals/return'
        }
        this.$axios({
          url: this.localpath + url,
          method: 'post',
          data: JSON.stringify(param),
          //发送格式为json
          headers:
              {
                'Content-Type': 'application/json',
                'dataType': 'json',
                'Authorization': localStorage.getItem("token")
              }
        }).then(res => {
          if (res.data.ok) {
            this.$message({
              message: '归还成功',
              type: 'success'
            })
          } else {
            this.$message({
              message: res.data.msg,
              type: 'error'
            })
          }
        })
      }
    },


    setupCamera() {
      const video = this.$refs.video;

      navigator.mediaDevices
          .getUserMedia({ video: true })
          .then((stream) => {
            this.videoStream = stream; // 保存视频流
            video.srcObject = stream;
            video.addEventListener("play", this.startDrawing);

            //  检查摄像头是否支持调焦距(原生的,只能通过调用光学元器件调焦的那种),zoom不为undefined即表明支持
            const track = stream.getVideoTracks()[0];
            const capabilities = track.getCapabilities();
            console.log("zoom:"+capabilities.zoom)
            if (capabilities.zoom) {
              this.supportsZoom = true;
              this.maxZoom = capabilities.zoom.max || 1; // 设置最大焦距
              this.zoomLevel = capabilities.zoom.min || 1; // 设置最小焦距
              this.zoom = true;
            }

          })
          .catch((err) => {
            console.error("Error accessing the camera: ", err);
            this.$message({ // 错误提示
              message: '访问摄像头失败: ' + err.message,
              type: 'error'
            });
          });
    },
    startDrawing() {
      this.updateCanvas();
    },
    updateCanvas() {
      if(this.zoom === false)
      {
        // ElMessage.info("调用的不是原生的镜头")
        const video = this.$refs.video;
        const canvas = this.$refs.canvas;
        const ctx = canvas.getContext("2d");

        // 设置 canvas 尺寸
        canvas.width = this.canvasWidth;
        canvas.height = this.canvasHeight;

        const zoomFactor = this.zoomLevel;

        // 清除 canvas
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        // 计算缩放后的视频宽高
        const videoWidth = video.videoWidth;
        const videoHeight = video.videoHeight;
        const scaledWidth = videoWidth / zoomFactor;
        const scaledHeight = videoHeight / zoomFactor;

        // 计算偏移量以实现缩放效果
        const offsetX = (videoWidth - scaledWidth) / 2;
        const offsetY = (videoHeight - scaledHeight) / 2;

        // 将视频绘制到 canvas 上
        ctx.drawImage(
            video,
            offsetX,
            offsetY,
            scaledWidth,
            scaledHeight,
            0,
            0,
            canvas.width,
            canvas.height
        );

        // 请求下一帧
        requestAnimationFrame(this.updateCanvas);
      }else {
        const track = this.videoStream.getVideoTracks()[0];
        // ElMessage.info("调用的是原生的镜头")
        track.applyConstraints({
          advanced: [{ zoom: this.zoomLevel }],
        })
            .then(() => {
              console.log(`焦距设置为: ${this.zoomLevel}`);
              ElMessage.info(`焦距设置为: ${this.zoomLevel}`)
            })
            .catch((error) => {
              console.error('调整焦距时出错:', error);
              ElMessage.error(error.toString());
            });
      }
    },

    // 关闭摄像头
    stopCamera() {
      if (this.videoStream) {
        this.videoStream.getTracks().forEach((track) => {
          track.stop(); // 停止每个轨道
        });
        this.videoStream = null; // 清空流
      }

      const video = this.$refs.video;
      video.pause();
      video.srcObject = null; // 清除视频流源

      if (this.animationFrameId) {
        cancelAnimationFrame(this.animationFrameId);
        this.animationFrameId = null; // 清空动画帧 ID
      }
    },

    async  startDig(){
      this.dialogVisible1 = true; // 打开弹窗
      this.supportsZoom = true;
      this.$nextTick(() => {
        this.setupCamera()

      });

    },

    takePhoto() {
      const video = this.$refs.video;
      const canvas = this.$refs.canvas;
      const context = canvas.getContext('2d');

      // 确保画布大小与视频相同
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;

      // 获取缩放比例
      const zoomFactor = this.zoomLevel;

      // 计算缩放后的绘制区域
      const zoomedWidth = canvas.width / zoomFactor;
      const zoomedHeight = canvas.height / zoomFactor;
      const offsetX = (canvas.width - zoomedWidth) / 2; // 中心绘制的偏移量
      const offsetY = (canvas.height - zoomedHeight) / 2; // 中心绘制的偏移量

      // 清空画布
      context.clearRect(0, 0, canvas.width, canvas.height);

      // 将视频帧绘制到画布上，应用缩放
      context.drawImage(video, offsetX, offsetY, zoomedWidth, zoomedHeight, 0, 0, canvas.width, canvas.height);

      // 获取图像数据URL
      const dataURL = canvas.toDataURL('image/png');

      // 获取图像数据URL（格式为 jpg）
      // const dataURL = canvas.toDataURL('image/jpeg');

      // 检查 dataURL 是否生成
      console.log('生成的 dataURL:', dataURL);

      // 将 dataURL 转换为 Blob
      fetch(dataURL)
          .then(res => {
            if (!res.ok) {
              throw new Error('无法获取 Blob');
            }
            return res.blob();
          })
          .then(blob => {
            this.photoFile = new File([blob], 'photo.png', { type: 'image/png' }); // 创建文件对象
            this.photoURL = dataURL; // 保存图像URL以便显示
            this.uploadPhoto();
          })
          .catch(err => {
            console.error('转换为 Blob 失败:', err);
          });

      // 预览照片
      this.photoData = dataURL; // 用于显示拍摄的照片
    }
    ,



    // 将 base64 数据转换为 Blob 对象
    dataURLtoBlob(dataURL) {
      const byteString = atob(dataURL.split(',')[1]); // 解码 base64 数据
      const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0]; // 获取 MIME 类型

      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }

      return new Blob([ab], { type: mimeString });
    },

    uploadPhoto() {
      if (!this.photoFile) {
        console.error("没有照片可上传");
        return;
      }

      // 创建 FormData 对象
      const formData = new FormData();
      formData.append('file', this.photoFile, 'photo.png'); // 将 Blob 添加到 FormData 中作为文件

      // 使用 axios 进行文件上传
      this.$axios({
        // url: 'http://192.168.6.139:8000/readers/byface',
        url: this.localpath + 'readers/byface',
        method: 'post',
        data: formData, // 传递 FormData
        headers: {
          'Content-Type': 'multipart/form-data', // 这里可以不设置，axios 会自动设置
          'Authorization': localStorage.getItem("token")
        }
      }).then(res => {
        if (res.data.ok) {
          this.photoData = null; // 上传成功后清空显示的照片
          this.photoFile = null; // 上传成功后清空照片文件
          ElMessage.success('人脸识别成功')
          this.formJYModel.StrReaderCardNumber = res.data.msg.StrReaderCardNumber;
          //如果请求成功，拿到值再说
          console.log("人脸识别后的信息"+res.data.msg.StrReaderCardNumber)
          // this.getReturnReader(res.data.msg.StrReaderCardNumber)
          // this.submit(res.data.msg.StrReaderCardNumber)
          // this.getborrowReader(res.data.msg.StrReaderCardNumber)
          // this.getReturnReader(res.data.msg.StrReaderCardNumber)
          this.getborrowReaderFace(res.data.msg.StrReaderCardNumber)
          this.$nextTick(() => {
            this.handleClose()
          });

        } else {
          this.$message({
            message: res.data.msg,
            type: 'error'
          });
        }
      }).catch(error => {
        ElMessage.error("上传失败，请重试"+error);
        console.error('上传失败:', error);
      });
    },


    //原生的调焦方法,不通过canvas,而是直接调用摄像头来进行焦距的调整
    // adjustZoom() {
    //   const track = this.stream.getVideoTracks()[0];
    //   track.applyConstraints({
    //     advanced: [{ zoom: this.zoomLevel }],
    //   })
    //       .then(() => {
    //         console.log(`焦距设置为: ${this.zoomLevel}`);
    //         ElMessage.info(`焦距设置为: ${this.zoomLevel}`)
    //       })
    //       .catch((error) => {
    //         console.error('调整焦距时出错:', error);
    //         ElMessage.error(error.toString());
    //       });
    // },

  },


}

/**
 * cream face
 */

</script>

<style scoped>
.el-message-box__message{
  font-size: 18px;
  color: red;
}
.disabled /deep/ .el-upload--picture-card{
  display:none
}
.borrow-statistics{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.borrow-statistics_num{
  color:blue;
}
.borrow-desc{
  flex:1;
  margin: 0;
}

.media-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: calc(100% - 80px); /* 留出按钮区域的空间 */
}

.video-container {
  flex: 1;
  position: relative;
}

.photo-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.video-container canvas {
  width: 100%; /* 画布宽度自适应 */
  height: auto; /* 高度根据宽度调整 */
  border: 1px solid #ccc; /* Canvas 边框 */
}

.photo-container img {
  width: 100%; /* 照片宽度自适应 */
  height: auto; /* 高度根据宽度调整 */
  border: 1px solid #ccc; /* 图片边框 */
}

.button-container {
  text-align: center;
  margin-top: 10px;
}
.photo-container h3 {
  margin-top: -40px; /* 调整负值让标题往上移动 */
}

</style>
